import React, {useEffect, useState} from "react"
import {Container,Row,Col,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import dateFormat from 'dateformat';
import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import VideoModule from "./video-module"

import { useContainerContext } from "../../store/ContainerContext";

import Title_Block from  "./title_block"; 
import TimeTrackerComp from "./time_tracker"

const AdvanceBuyer = (props) => {

    const context = useContainerContext();
    const [ property_data, setPropertyData ] = useState(props.property_data);

    const [ playVideo, setPlayVideo ] = useState(true);
    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('advance-buyer-program');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    //console.log("property_data", property_data);

    return <>
        <TimeTrackerComp TabName={'Buyers'} />

        <div className="section-wrapper">
          <Container>

            <Title_Block Title={article_data && article_data.Title} Type={props.Type} PreviousLink={''} NextLink={''} NextPage={props.NextPage} PrevPage={props.PrevPage} instruct={true} />

            <div className="profile-wrapper advance_buyer">
                <Row>
                    <Col>
                    <div className="profile-left">
                        <div className="profile-content">
                        {article_data && HTMLReactParser(article_data.Modules[0].Content)}
                        </div>

                        {/* {
                            article_data && article_data.Modules.map((module, idex) => 
                                (
                                    module.Advance_Buyer_List && module.Advance_Buyer_List.map((buyer, index2)=>{
                                        return(
                                            <div className="profile-info">
                                                <div className="info-content">
                                                    <strong>{buyer.Buyer_Name}</strong>
                                                    <span>{buyer.Category}</span>
                                                    <span>£{buyer.Price}</span>
                                                </div>
                                                
                                                <div className="info-list">{buyer.Content && HTMLReactParser(buyer.Content)}</div>
                                                
                                            </div>
                                        )
                                    })
                                )

                            )
                        } */}
                        {
                            property_data && property_data.previewings && property_data.previewings.length > 0 && property_data.previewings.map((buyer, idex) => {
                                var DateOfViewing= buyer.DateOfViewing && dateFormat(buyer.DateOfViewing, "dd/mm/yy") +" at "+ dateFormat(buyer.DateOfViewing, "UTC:h:MMtt");

                                return(
                                    <div className="profile-info">
                                        <div className="info-content">
                                            <strong>{buyer.ListOfBuyers}</strong>
                                            <span>Proposed viewing date</span>
                                            <span>{DateOfViewing}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>  
                    {/* profile-left */}
                    </Col>
                    <Col>
                    <div className="profile-right">
                        <div className="video-wrap rectangle_video advance_video mb-0">
                            {article_data && article_data.Modules && article_data.Modules.length > 0 && article_data.Modules[0].Embed_Video_URL && 
                            <VideoModule video_url={article_data.Modules[0].Embed_Video_URL} width="100%" height={"100%"} playing={false} loop={false} />}
                        {/* {
                            playVideo ? (
                                <VideoModule video_url={article_data && article_data.Modules && article_data.Modules.length > 0 && article_data.Modules[0].Upload_Video ? strapi_url+''+article_data.Modules[0].Upload_Video.url : article_data.Modules[0].Embed_Video_URL} playing={true} />
                            ) : (
                                <>
                                    <figure>
                                        { article_data && article_data.Modules[0].Upload_Image && <img src={strapi_url+''+article_data.Modules[0].Upload_Image.url} alt="video-img" /> }
                                    </figure>
                                    <a href="javascript:;" onClick={() => setPlayVideo(true) }>
                                        <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                        <path d="M20.8411 10.5974L3.55536 0.326278C2.15089 -0.50785 0 0.3016 0 2.36471V22.902C0 24.7529 1.99866 25.8684 3.55536 24.9405L20.8411 14.6743C22.383 13.7612 22.3879 11.5105 20.8411 10.5974Z" fill="#CC0133"/>
                                        </g>
                                        <defs>
                                        <clipPath>
                                        <rect width="22" height="25.2703" fill="white"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                    </a>
                                </>
                            )
                        } */}
                        </div>
                    </div>
                    {/* profile-right */}
                    </Col>
                </Row>
            </div>
            </Container>
        </div>
      {/* profile-wrapper */}
    </>
}

export default AdvanceBuyer